



















import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
  computed,
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TimedAlert from "~/components/TimedAlert.vue";
import CookieBanner from "~/components/CookieBanner.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    LazyHydrate,
    LoadWhenVisible,
    TimedAlert,
    CookieBanner,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { app } = useContext();
    const story = ref({ content: { metatags: {} } });

    const baseUrl = app.$config.baseUrl;
    const locale = app.i18n.locale;

    const metatags = computed(() => story.value.content.metatags);

    useFetch(async () => {
      await app.$storyblok("home").then((res) => {
        story.value.content = res.story.content;
      });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: "home" }]);
    });

    // @ts-ignore
    return {
      story,
      metatags,
      baseUrl,
      locale,
    };
  },
  head() {
    return getMetaInfo(this.metatags, this.locale, this.baseUrl);
  },
});
